import { createContext, memo, useEffect, useState } from 'react';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import {
  Feature,
  FeatureToggleFragment,
  GlobalFields,
} from '@hultafors/hellberg/types';

export interface GlobalContextValue
  extends Omit<GlobalFields, 'featureToggle'> {
  appInsights?: ApplicationInsights;
  isEnabled(feature: string): boolean;
}

export const GlobalContext = createContext<GlobalContextValue | null>(null);

export interface GlobalProviderProps {
  children?: React.ReactNode;
  value: GlobalFields;
}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({
  children,
  value,
}) => {
  const [featureToggle] = useState<FeatureToggleFragment>(value.featureToggle);
  const [appInsights, setAppInsights] = useState<ApplicationInsights>();

  useEffect(() => {
    if (!appInsights && process.env['APPINSIGHTS_INSTRUMENTATIONKEY']) {
      setAppInsights(
        new ApplicationInsights({
          config: {
            instrumentationKey: process.env['APPINSIGHTS_INSTRUMENTATIONKEY'],
          },
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (appInsights) {
      appInsights.loadAppInsights();
      appInsights.trackPageView();
    }
  }, [appInsights]);

  const isEnabled = (feature: Feature) => {
    return featureToggle?.[feature];
  };

  return (
    <GlobalContext.Provider
      value={{
        ...value,
        appInsights,
        isEnabled,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const GlobalProviderMemo = memo(GlobalProvider);

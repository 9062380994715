import { ReactNode } from 'react';

import { LazyMotion } from 'framer-motion';
import { I18NConfig } from 'next/dist/server/config-shared';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { GlobalFields } from '@hultafors/hellberg/types';

import { CartProvider } from '../CartContext/CartContext';
import { FavoritesProvider } from '../FavoritesContext/FavoritesContext';
import { GlobalProvider } from '../GlobalContext/GlobalContext';

const MarketPickerProvider = dynamic(() =>
  import('@hultafors/shared/context').then(
    (module) => module.MarketPickerProvider,
  ),
);

const SWRProvider = dynamic(() =>
  import('@hultafors/shared/context').then((module) => module.SWRProvider),
);

const lazyMotionFeatures = () =>
  import('./lazy-motion-features').then((res) => res.default);

export interface ApplicationProviderProps {
  children?: ReactNode;
  value: GlobalFields;
  i18nConfig: I18NConfig;
}

export const ApplicationProvider: React.FC<ApplicationProviderProps> = ({
  children,
  value,
  i18nConfig,
}) => {
  const { locale } = useRouter();

  /* Saving below code for later use

  const active = useMemo(() => {
    const actualLocale = locale ?? defaultLocale ?? 'en';
    const hostname =
    i18nConfig?.domains?.find((item) => item.locales?.includes(actualLocale))
        ?.domain ?? 'www.hellbergsafety.com';
        return `https://${hostname}`;
      }, [locale, defaultLocale, i18nConfig]).replace('https://', '');

  */

  function getActiveLocale(locale: string | undefined) {
    switch (locale) {
      case 'de-de':
        return 'www.hellbergsafety.de';
      case 'da-dk':
        return 'www.hellbergsafety.dk';
      case 'fi-fi':
        return 'www.hellbergsafety.fi';
      case 'en-ie':
        return 'www.hellbergsafety.ie';
      case 'nl-nl':
        return 'www.hellbergsafety.nl';
      case 'nb-no':
        return 'www.hellbergsafety.no';
      case 'sv-se':
        return 'www.hellbergsafety.se';
      case 'en-gb':
        return 'www.hellbergsafety.co.uk';
      case 'en-ca':
        return 'www.hellbergsafety.ca';
      case 'en-us':
        return 'www.hellbergsafety.us';
      default:
        return 'www.hellbergsafety.com'; // Return null or handle the default case as needed
    }
  }

  const active = getActiveLocale(locale).replace('https://', '');

  return (
    <SWRProvider>
      <LazyMotion features={lazyMotionFeatures}>
        <GlobalProvider value={value}>
          <CartProvider siteLocale={value.siteLocale}>
            <FavoritesProvider siteLocale={value.siteLocale}>
              <MarketPickerProvider
                label={value?.shared?.changeLanguage ?? ''}
                markets={value?.shared?.languages || []}
                active={active}
              >
                {children}
              </MarketPickerProvider>
            </FavoritesProvider>
          </CartProvider>
        </GlobalProvider>
      </LazyMotion>
    </SWRProvider>
  );
};
